import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { HasScrollable } from '../has-scrollable';
import { ProviderService } from '../provider.service';
import { ToasterService } from '../toaster.service';

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss']
})
export class ProgramDetailComponent implements OnInit, HasScrollable {

  private projID: number;
  private detailId: number;

  detail = {
    name: '',
    description: '',
  };

  slides = [];

  private getData = function(dId: number) {
    this.api.getProgrammDetail(dId).subscribe((data: any) => {
      this.detail.name = data.name;
      this.detail.description = data.description;
      data.images.forEach(element => {
        this.slides.push(this.api.getApiRoot() + element);
      });
      this.provider.setContentLoading(false);
    }, (err: HttpErrorResponse) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private api: ApiService, private provider: ProviderService, private toaster: ToasterService) { 
    this.route.params.subscribe( params => {
      this.projID = params.id;
      this.detailId = params.detId;
      //this.provider.updateRootId(this.projID);
    });

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
  }
  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled = state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData(this.detailId);
  }

}
