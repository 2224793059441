import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherDate'
})
export class WeatherDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let tzo = super.transform(args*1000, 'HHmm');
    return super.transform(value*1000, "EEEEEE", tzo);
  }

}
