<div class="container" navScrollable (scrolled)="setScrollState($event)">
    <GalleryHeader [images]="slides" [title]="acc.name" [isScrolled]="isScrolled"></GalleryHeader>
    <p-fieldset legend="Details" class="description">
        <p [innerHTML]=acc.description></p>
        <div class="footer">
            <p *ngIf="acc.address" class="card-footer-element" [innerHTML]=acc.address></p>
            <a *ngIf="acc.phone" href="tel:{{ acc.phone }}" pRipple><p class="card-footer-element" [innerHTML]=acc.phone ></p></a>
            <a *ngIf="acc.website" href="{{ acc.website }}" target="_blank" pRipple><p class="card-footer-element">Webseite</p></a>
        </div>
    </p-fieldset>             
</div>