import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';
import { ApiService } from '../api.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HasScrollable } from '../has-scrollable';

@Component({
  selector: 'app-destination-detail',
  templateUrl: './destination-detail.component.html',
  styleUrls: ['./destination-detail.component.scss']
})
export class DestinationDetailComponent implements OnInit, HasScrollable {

  private projID: number;
  private destId: number;

  destination = {
    name: '',
    description: '',
  };

  slides = [];

  private getData = function(dId: number) {
    this.api.getDestination(dId).subscribe((data: any) => {
      this.destination.name = data.name;
      this.destination.description = data.description;
      data.images.forEach(element => {
        this.slides.push(this.api.getApiRoot() + element);
      });

      this.provider.setContentLoading(false);
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private provider: ProviderService, private api: ApiService, private toaster: ToasterService) {
    this.route.params.subscribe( params => {
      //this.projID = params.id;
      this.destId = params.desId;
      //this.provider.updateRootId(this.projID);
    });

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
  }

  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled = state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData(this.destId);
  }

}
