import { Component, OnInit } from '@angular/core';
import { DateFormatterService } from '../date-formatter.service';
import { ApiService } from '../api.service';
import { ProviderService } from '../provider.service';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HasScrollable } from '../has-scrollable';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, HasScrollable {

  private projID: number;

  notifications = [];

  getData = function() {
    this.api.getNotifications().subscribe((data: any) => {

      data.forEach((element) => {
        this.notifications.push({
          title: element.title,
          text: element.text,
          //date: element.timestamp ? this.dateFormatter.formatTime(new Date(new Date(element.timestamp).getTime()/*  + 3600000 */)) : undefined,
          timestamp: element.timestamp ? new Date(element.timestamp) : undefined,
        });
      });
      this.provider.setContentLoading(false);
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private provider: ProviderService, private api: ApiService, private dateFormatter: DateFormatterService, private toaster: ToasterService) {
    this.route.params.subscribe( params => {
      this.projID = params.id;
      //this.provider.updateRootId(this.projID);
    });

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
  }

  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled = state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData();
  }

}
