import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'GalleryHeader',
  templateUrl: './gallery-header.component.html',
  styleUrls: ['./gallery-header.component.scss']
})
export class GalleryHeaderComponent implements OnInit, OnChanges {

  @Input() title:string;
  @Input() images:string[];

  @Input() isScrolled:boolean;
  
  @ViewChild('nav') nav:ElementRef;

  elevated: boolean = false;

  openGallery: boolean = false;

  galleryResponsiveOpt:any[] = [
    {
        breakpoint: '1500px',
        numVisible: 8
    },
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 4
    },
    {
        breakpoint: '560px',
        numVisible: 3
    }
];

  constructor(private location:Location) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.nav && changes && changes.isScrolled) {
      if(!this.elevated && this.isScrolled) {
        this.nav.nativeElement.classList.add('elevated');
        this.elevated = true;
      } else if(this.elevated && !this.isScrolled) {
        this.nav.nativeElement.classList.remove('elevated');
        this.elevated = false;
      }
    }
  }

  goBack() {
    this.location.back();
  }
}
