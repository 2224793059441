import { Component, OnInit, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';
import { ApiService } from '../api.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HasScrollable } from '../has-scrollable';

@Component({
  selector: 'app-acc-detail',
  templateUrl: './acc-detail.component.html',
  styleUrls: ['./acc-detail.component.scss']
})
export class AccDetailComponent implements OnInit, HasScrollable {

  private projID: number;
  private accId: number;

  acc = {
    name: '',
    description: '',
    address: '',
    phone: '',
    website: ''
  };

  slides = [];

  private getData = function(aId: number) {
    this.api.getAccommodation(aId).subscribe((data: any) => {
      this.acc.name = data.name;
      this.acc.description = data.description;
      this.acc.address = data.address;
      this.acc.phone = data.phone;
      this.acc.website = data.website;
      data.images.forEach(element => {
        this.slides.push(this.api.getApiRoot() + element);
      });
      this.provider.setContentLoading(false);
    }, ( err:HttpErrorResponse) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private provider: ProviderService, private api: ApiService, private toaster: ToasterService) {
    this.route.params.subscribe( params => {
      this.projID = params.id;
      this.accId = params.accId;
      //this.provider.updateRootId(this.projID);
    });

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });

  }
  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled = state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData(this.accId);
  }

}
