import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs';

@Component({
  selector: 'MenuBarComponent',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements AfterViewInit {

  @ViewChild('bar') menuBar: ElementRef;

  constructor( private router: Router) {
  }

  ngAfterViewInit(): void {

    this.router.events
      .pipe(
          filter((event) => event instanceof NavigationEnd),
          startWith(this.router)
      )
      .subscribe((event: NavigationEnd) => {
        if(!this.menuBar) return;
        const path = this.router.url;

        this.menuBar.nativeElement.querySelector('.active')?.classList.remove('active');

        if(path.startsWith('/program')) {
          this.menuBar.nativeElement.querySelector('#m-pro').classList.add('active');
        } else if(path.startsWith('/accomodations')) {
          this.menuBar.nativeElement.querySelector('#m-acc').classList.add('active');
        } else if(path.startsWith('/transport')) {
          this.menuBar.nativeElement.querySelector('#m-tra').classList.add('active');
        } else if(path.startsWith('/notice')) {
          this.menuBar.nativeElement.querySelector('#m-div').classList.add('active');
        } else {
          this.menuBar.nativeElement.querySelector('#m-home').classList.add('active');
        }
      })
  }

}
