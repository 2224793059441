import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';
import { ApiService } from '../api.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-map-detail',
  templateUrl: './map-detail.component.html',
  styleUrls: ['./map-detail.component.scss']
})
export class MapDetailComponent implements OnInit {

  //private projID: number;
  private mapId: number;

  map = {
    name: '',
    description: '',
    img: ''
  };

  getData = function(id: number) {
    this.api.getMap(this.mapId).subscribe((data: any) => {
      this.map.name = data.name;
      this.map.description = data.description;
      this.map.img = this.api.getApiRoot() + data.img;
      this.provider.setContentLoading(false);
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private provider: ProviderService, private api: ApiService, private toaster: ToasterService) {
    this.route.params.subscribe( params => {
      //this.projID = params.id;
      this.mapId = params.mapId;
      //this.provider.updateRootId(this.projID);
    });

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData(this.mapId);
  }

}
