import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderService } from '../provider.service';
import { ApiService } from '../api.service';
import { DateFormatterService } from '../date-formatter.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HasScrollable } from '../has-scrollable';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})

export class ProgramComponent implements OnInit, HasScrollable {

  //@ViewChild('tabGroup', {static: false}) tabGroup: TabView;
  projID: number;
  activeTab: number;
  index: number = 0;
  days = [];

  getData() {
    this.api.getProgram().subscribe({
      next: (data: any) => {
        const sortedDays = data.sort( (a, b) =>  a.date.localeCompare(b.date));
        sortedDays.map( day => {
          day.date=new Date(day.date);
          day.formattedDate = this.dateFormatter.formatDate(new Date(day.date.getTime()));
        });
        this.days = sortedDays;
        
        const currDay=new Date();
        sortedDays.forEach((day, index: number) => {
          if (day.date.getDate() == currDay.getDate() && day.date.getMonth() == currDay.getMonth()) {
            this.activeTab = index;
          }
        })
      }, error: (err:HttpErrorResponse) => {
        this.toaster.handleApiError();
        throw err;
      }
    });
  }

  constructor(
    private router: Router, private route: ActivatedRoute, private provider: ProviderService,
    private api: ApiService, private dateFormatter: DateFormatterService, private toaster: ToasterService) {
    this.route.params.subscribe( params => {
      this.projID = params.id;
      //this.provider.updateRootId(this.projID);
    });
    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
    this.activeTab = this.provider.lastProgramIndex;
  }

  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled = state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData();
    this.provider.resetProgramIndex();
  }

  goDetail(id: number) {
    this.provider.lastProgramIndex = this.index;
    this.router.navigate(['details', id], {relativeTo: this.route});
  }

  handleChange(e) {
    this.index = e.index;
  }

}
