import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  private cacheName = 'shared-cache';

  private mRouteDepth = new Subject<number>();
  //private mRootProj = new Subject<number>();
  private _lastProgramIndex: number;

  private mIsContentLoading = new Subject<boolean>();
  private mErrorLoading = new Subject<boolean>();

  private mIsNav = new Subject<boolean>();

  routeDepth$ = this.mRouteDepth.asObservable();
  //root$ = this.mRootProj.asObservable();

  isContentLoading$ = this.mIsContentLoading.asObservable();
  errorLoading$ = this.mErrorLoading.asObservable();
  isNav$ = this.mIsNav.asObservable();

  /*updateRootId(root: number): void {
    this.mRootProj.next(root);
  }*/

  setContentLoading(loading: boolean): void {
    this.mIsContentLoading.next(loading);
  }

  setErrorLoading(error: boolean): void {
    this.mErrorLoading.next(error);
    if ( error ) { this.setContentLoading(false); }
  }

  updateRouteDepth(depth: number): void {
    this.mRouteDepth.next(depth);
  }

  nav(isNav: boolean): void {
    this.mIsNav.next(isNav);
  }

  isIos(): boolean {
    const userAgent = navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
  }

  /* angular.json
  "optimization": true,
              "outputHashing": "all",
              "sourceMap": false,
              "extractCss": true,
              "namedChunks": false,
              "aot": true,
              "extractLicenses": true,
              "vendorChunk": false,
              "buildOptimizer": true,

            */

  isInstalled(installing?: boolean): boolean {
    let isIn = localStorage.getItem('installed') ? true : false;
    if ( installing ) {
      localStorage.setItem('installed', 'true');
    }
    return isIn;
  }

  setToken(token: string) {

    localStorage.setItem('token', token);


    /*localStorage.setItem('token', token);

    if(this.isIos()) {
      console.log(window);
      if('caches' in window || 'CacheStorage' in window) {
        caches.open(this.cacheName).then((cache) => {
          cache.put('token', new Response(token));
          //return true
        }).catch((err) => {
          console.error(err)
          return false
        })
        
      } else {
        console.log('caches not in window, refreshing');
        location.reload();
        //console.log(caches)
        //return false;
      }
    }
    localStorage.setItem('token', token);
    return true*/

  }

  getToken(): string {
    /*if(this.isIos()) {
      if(localStorage.getItem('token')) {
        return localStorage.getItem('token');
      }
      else if('caches' in window) {
        caches.match('token').then((response) => { 
          
          return response.text().then( tkn => {
            console.log(tkn)
            return tkn;
          })
            //return response.text();
        }).catch((err) => {
          console.error(err)
            //TODO catch error and provide user with feedback
        })
      }
    } else {
      return localStorage.getItem('token');
    }
    */
    return localStorage.getItem('token');
  }

  /* mirrorToken(): Promise<boolean> {
    /if('caches' in window) {
      caches.match('token').then((response) => {
        response.text().then( tkn => {
          localStorage.setItem('token', tkn);
          return true;
        })
          //return response.text();
      }).catch((err) => {
        console.error(err)
        return false;
          //TODO catch error and provide user with feedback
      })
    }
    return false;/
    const res = await this.api.getTkn();
    if(res) {
      this.setToken(res);
      return true;
    } else {
      return false;
    }
    /* this.api.getTkn().then(
      (data) => {
        this.setToken(data);
        return true;
      }).catch((err) => {
        console.error(err)
        return false;
      }); /
  }*/

  set lastProgramIndex(index: number) {
    this._lastProgramIndex = index;
  }

  get lastProgramIndex(): number {
    return this._lastProgramIndex;
  }

  resetProgramIndex(): void {
    this._lastProgramIndex = 0;
  }

  pageLoading() {
    this.setContentLoading(true);
    this.setErrorLoading(false);
  }

  constructor() { 
    this._lastProgramIndex = 0;
    this.mErrorLoading.next(false);
    this.mIsContentLoading.next(false);
  }
}
