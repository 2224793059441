<div class="container" navScrollable (scrolled)="setScrollState($event)">
    <NavComponent title="Programm" [isScrolled]="isScrolled"></NavComponent>
    <p-tabView [scrollable]="true" *ngIf="days.length > 0" (onChange)="handleChange($event)" [activeIndex]="activeTab">
        <p-tabPanel *ngFor="let day of days; let i = index">
            <ng-template pTemplate="header">
                <strong> {{ day.title }} </strong>
                <span>{{ day.formattedDate }}</span>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="entry-group" *ngIf="day.dawn.length!=0">
                    <h5 >Morgen</h5>
                    <p-timeline [value]="day.dawn" align="left" class="program-timeline">
                        <ng-template pTemplate="marker" let-entry >
                            <div class="p-timeline-event-marker" [class.detail-marker]="entry.detailId"></div>
                        </ng-template>
                        <ng-template pTemplate="content" let-entry>
                            <div class="day-entry-container">
                                <div class="entry-ripple" pRipple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                                <p class="day-entry" [innerHTML]="entry.title"></p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                            </div>
                        </ng-template>
                    </p-timeline>
                </div>
    
                <div class="entry-group" *ngIf="day.morning.length!=0">
                    <h5 >Vormittag</h5>
                    <p-timeline [value]="day.morning" align="left" class="program-timeline">
                        <ng-template pTemplate="marker" let-entry >
                            <div class="p-timeline-event-marker" [class.detail-marker]="entry.detailId"></div>
                        </ng-template>
                        <ng-template pTemplate="content" let-entry>
                            <div class="day-entry-container">
                                <div class="entry-ripple" pRipple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                                <p class="day-entry" [innerHTML]="entry.title"></p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                            </div>
                        </ng-template>
                    </p-timeline>
                </div>
                
                <div class="entry-group" *ngIf="day.noon.length!=0">
                    <h5 >Mittag</h5>
                    <p-timeline [value]="day.noon" align="left" class="program-timeline">
                        <ng-template pTemplate="marker" let-entry >
                            <div class="p-timeline-event-marker" [class.detail-marker]="entry.detailId"></div>
                        </ng-template>
                        <ng-template pTemplate="content" let-entry>
                            <div class="day-entry-container">
                                <div class="entry-ripple" pRipple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                                <p class="day-entry" [innerHTML]="entry.title"></p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                            </div>
                        </ng-template>
                    </p-timeline>
                </div>
    
                <div class="entry-group" *ngIf="day.afternoon.length!=0">
                    <h5 >Nachmittag</h5>
                    <p-timeline [value]="day.afternoon" align="left" class="program-timeline">
                        <ng-template pTemplate="marker" let-entry >
                            <div class="p-timeline-event-marker" [class.detail-marker]="entry.detailId"></div>
                        </ng-template>
                        <ng-template pTemplate="content" let-entry>
                            <div class="day-entry-container">
                                <div class="entry-ripple" pRipple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                                <p class="day-entry" [innerHTML]="entry.title"></p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                            </div>
                        </ng-template>
                    </p-timeline>
                </div>
    
                <div class="entry-group" *ngIf="day.evening.length!=0">
                    <h5 >Abend</h5>
                    <p-timeline [value]="day.evening" align="left" class="program-timeline">
                        <ng-template pTemplate="marker" let-entry >
                            <div class="p-timeline-event-marker" [class.detail-marker]="entry.detailId"></div>
                        </ng-template>
                        <ng-template pTemplate="content" let-entry >
                            <div class="day-entry-container" (click)="goDetail(entry.detailId)">
                                <div class="entry-ripple" pRipple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                                <p class="day-entry" [innerHTML]="entry.title"></p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                            </div>
                        </ng-template>
                    </p-timeline>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
    <!--mat-tab-group mat-align-tabs="start" animationDuration="220ms" #tabGroup [(selectedIndex)]=activeTab>
        <mat-tab *ngFor="let day of days; let i = index" isActive="index==0">
            <ng-template mat-tab-label>
                <strong> {{ day.title }} </strong><br>
                <span>{{ day.date }}</span>
            </ng-template>
            <div class="entry-group">
                <h5 *ngIf="day.dawn.length!=0">Morgen</h5>
                <div *ngFor="let entry of day.dawn" class="day-entry-container">
                    <div class="entry-ripple" mat-ripple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                    <span class="entry-listing-dot"></span><p class="day-entry">{{ entry.title }}</p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                </div>
            </div>

            <div class="entry-group">
                <h5 *ngIf="day.morning.length!=0">Vormittag</h5>
                <div *ngFor="let entry of day.morning" class="day-entry-container">
                    <div class="entry-ripple" mat-ripple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                    <span class="entry-listing-dot"></span><p class="day-entry">{{ entry.title }}</p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                </div>
            </div>
            
            <div class="entry-group">
                <h5 *ngIf="day.noon.length!=0">Mittag</h5>
                <div *ngFor="let entry of day.noon" class="day-entry-container">
                    <div class="entry-ripple" mat-ripple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                    <span class="entry-listing-dot"></span><p class="day-entry">{{ entry.title }}</p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                </div>
            </div>

            <div class="entry-group">
                <h5 *ngIf="day.afternoon.length!=0">Nachmittag</h5>
                <div *ngFor="let entry of day.afternoon" class="day-entry-container">
                    <div class="entry-ripple" mat-ripple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                    <span class="entry-listing-dot"></span><p class="day-entry">{{ entry.title }}</p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                </div>
            </div>

            <div class="entry-group">
                <h5 *ngIf="day.evening.length!=0">Abend</h5>
                <div *ngFor="let entry of day.evening" class="day-entry-container">
                    <div class="entry-ripple" mat-ripple *ngIf="entry.detailId" (click)="goDetail(entry.detailId)"></div>
                    <span class="entry-listing-dot"></span><p class="day-entry">{{ entry.title }}</p><i class="detail-arrow fa-solid fa-arrow-right" *ngIf="entry.detailId" aria-hidden="true"></i>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group-->
</div>