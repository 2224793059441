import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProgramComponent } from './program/program.component';
import { InfoComponent } from './info/info.component';
import { TransportComponent } from './transport/transport.component';
import { AccomodationComponent } from './accomodation/accomodation.component';
import { MapComponent } from './map/map.component';
import { NotificationComponent } from './notification/notification.component';
import { NoticeComponent } from './notice/notice.component';
import { AccDetailComponent } from './acc-detail/acc-detail.component';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import { DestinationDetailComponent } from './destination-detail/destination-detail.component';
import { MapDetailComponent } from './map-detail/map-detail.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  { path: '', component: HomeComponent, data: { depth: 1} },
  { path: 'program/details/:detId', component: ProgramDetailComponent, data: { depth: 3} },
  { path: 'program', component: ProgramComponent, data: { depth: 2} },
  { path: 'infos/destination/:desId', component: DestinationDetailComponent, data: { depth: 3} },
  { path: 'infos', component: InfoComponent, data: { depth: 2} },
  { path: 'transport', component: TransportComponent, data: { depth: 2} },
  { path: 'accomodations/details/:accId', component: AccDetailComponent, data: { depth: 3} },
  { path: 'accomodations', component: AccomodationComponent, data: { depth: 2} },
  { path: 'map/details/:mapId', component: MapDetailComponent, data: { depth: 3} },
  { path: 'map', component: MapComponent, data: { depth: 2} },
  { path: 'notifications', component: NotificationComponent, data: { depth: 2} },
  { path: 'notice', component: NoticeComponent, data: { depth: 2} },
  { path: 'login', component: LoginComponent, data: { depth: 2} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
