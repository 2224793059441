import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ApiService } from './api.service';
import { ProviderService } from './provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    //slideInAnimation
  ]
})

export class AppComponent {
  title = 'Eurotravel Companion';
  loading = false;
  isNav = true;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['depth'];
  }

  constructor(private api: ApiService, private provider: ProviderService, private primengConfig: PrimeNGConfig){
    this.provider.isContentLoading$.subscribe( value => {
      this.loading = value;
    });
    this.provider.isNav$.subscribe( value => {
      this.isNav = value;
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
