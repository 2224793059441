<!--div class="container">
    <mat-card *ngFor="let notification of notifications" class="notification card">
        <mat-card-content>
            <h3 [innerHTML]=notification.title></h3>
            <p *ngIf="notification.text" [innerHTML]=notification.text></p>
            <span class="time" [innerHTML]=notification.date></span>
        </mat-card-content>
    </mat-card>              
</div-->
<div class="container" navScrollable (scrolled)="setScrollState($event)">
    <NavComponent title="News" [isScrolled]="isScrolled"></NavComponent>
    <p-card 
        class="notification card" 
        pRipple
        *ngFor="let notification of notifications"
    >
        <ng-template pTemplate="header">
            <div class="header-wrapper">
                <h3>{{notification.title}}</h3>
            </div>
        </ng-template>
        <p-divider align="right">
            <div class="inline-flex align-items-center">
                <span *ngIf="notification.timestamp" class="time" >{{notification.timestamp | timeStamp}}</span><!-- [innerHTML]=notification.date -->
            </div>
        </p-divider>
        <p [innerHTML]=notification.text></p>
    </p-card>
</div>