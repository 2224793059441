<div #bar class="menubar">
    <div id="m-home" class="menu-item" routerLink="/">
        <!--i class="material-icons-outlined">home</i-->
        <i class="fa-solid fa-house"></i>
        <span>Home</span>
    </div>
    <div id="m-pro" class="menu-item" routerLink="/program">
        <!--i class="material-icons">event_note</i-->
        <i class="fa-solid fa-calendar-day"></i>
        <span>Programm</span>
    </div>
    <div id="m-acc" class="menu-item" routerLink="/accomodations">
        <!--i class="material-icons">single_bed</i-->
        <i class="fa-solid fa-bed"></i>
        <span>Unterkunft</span>
    </div>
    <div id="m-tra" class="menu-item" routerLink="/transport">
        <!--i class="material-icons">flight_takeoff</i-->
        <i class="fa-solid fa-plane-departure"></i>
        <span>Transport</span>
    </div>
    <div id="m-div" class="menu-item" routerLink="/notice">
        <!--i class="material-icons">format_list_bulleted</i-->
        <i class="fa-solid fa-bars"></i>
        <span>Mehr</span>
    </div>
</div>
