import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';
import { ApiService } from '../api.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HasScrollable } from '../has-scrollable';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, HasScrollable {

  //private projID: number;

  single=false;

  description = {
    text: '',
    available: false
  };

  destinations = [];
  infos = [];

  getDestinationData = function() {
    this.api.getDestinationList().subscribe((data: any) => {
      data.forEach((element) => {
        this.destinations.push({
          name: element.name,
          image: this.api.getApiRoot() + element.imageLink,
          id: element.dest_id,
        });
      });
      this.single = this.destinations.length==1;
      this.provider.setContentLoading(false);
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  getInformationData = function() {
    this.api.getInfos().subscribe((data: any) => {
      data.forEach((element) => {
        this.infos.push({
          title: element.title,
          text: element.text,
        });
      });
      this.provider.setContentLoading(false);
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private provider: ProviderService, private api: ApiService, private toaster: ToasterService) {
    /*this.route.params.subscribe( params => {
      //this.projID = params.id;
      //this.provider.updateRootId(this.projID);
    });*/

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
  }
  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled = state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getDestinationData();
    this.getInformationData();
  }

}
