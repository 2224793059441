import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherIcon'
})
export class WeatherIconPipe implements PipeTransform {

  /**
   * @param {number} code - weather group id from openweathermap
   * @returns {string} - material icon name for main weather group
   */
  weatherMap = {
    200: 'cloud-bolt',  // thunderstorm with light rain
    201: 'cloud-bolt',  // thunderstorm with rain
    202: 'cloud-bolt',  // thunderstorm with heavy rain
    210: 'cloud-bolt',  // light thunderstorm
    211: 'cloud-bolt',  // thunderstorm
    212: 'cloud-bolt',  // heavy thunderstorm
    221: 'cloud-bolt',  // ragged thunderstorm
    230: 'cloud-bolt',  // thunderstorm with light drizzle
    231: 'cloud-bolt',  // thunderstorm with drizzle
    232: 'cloud-bolt',  // thunderstorm with heavy drizzle
    300: 'cloud-rain', // light intensity drizzle
    301: 'cloud-rain', // drizzle
    302: 'cloud-rain', // heavy intensity drizzle
    310: 'cloud-rain', // light intensity drizzle rain
    311: 'cloud-rain', // drizzle rain
    312: 'cloud-rain', // heavy intensity drizzle rain
    313: 'cloud-rain', // shower rain and drizzle
    314: 'cloud-showers-heavy', // heavy shower rain and drizzle
    321: 'cloud-showers-heavy', // shower drizzle
    500: 'cloud-rain', // light rain
    501: 'cloud-rain', // moderate rain
    502: 'cloud-showers-heavy', // heavy intensity rain
    503: 'cloud-showers-heavy', // very heavy rain
    504: 'cloud-showers-heavy', // extreme rain
    511: 'cloud-showers-heavy', // freezing rain
    520: 'cloud-showers-heavy', // light intensity shower rain
    521: 'cloud-showers-heavy', // shower rain
    522: 'cloud-showers-heavy', // heavy intensity shower rain
    531: 'cloud-showers-heavy', // ragged shower rain
    600: 'snowflake',  // light snow
    601: 'snowflake',  // snow
    602: 'snowflake',  // heavy snow
    611: 'snowflake',  // sleet
    612: 'snowflake',  // shower sleet
    615: 'snowflake',  // light rain and snow
    616: 'snowflake',  // rain and snow
    620: 'cloud-rain',  // light shower snow
    621: 'cloud-rain',  // shower snow
    622: 'cloud-showers-heavy',  // heavy shower snow
    700: 'smog', // mist
    711: 'smog', // smoke
    721: 'smog', // haze
    731: 'smog', // sand, dust whirls
    741: 'smog', // fog
    751: 'smog', // sand
    761: 'smog', // dust
    762: 'smog', // volcanic ash
    771: 'smog', // squalls
    781: 'tornado', // tornado
    800: 'sun', // clear sky
    801: 'sun', // few clouds
    802: 'cloud-sun',  // scattered clouds
    803: 'cloud-sun', // broken clouds
    804: 'cloud', // overcast clouds
  }

  transform(code: number, ...args: unknown[]): string {
    return 'fa-'+this.weatherMap[code];
  }

}
