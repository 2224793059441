import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';
import { ApiService } from '../api.service';
import { DateFormatterService } from '../date-formatter.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HasScrollable } from '../has-scrollable';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit, HasScrollable {

  private projID: number;
  transports = [];

  getData = function() {
    this.api.getTransports().subscribe((data: any) => {
      const transportsRaw = [];

      data.forEach((element) => {
        let icon: string;
        switch (element.icon) {
          case 1:
            icon = 'fa-plane-departure';
            break;
          case 2:
            icon = 'fa-train';
            break;
          case 3:
            icon = 'fa-train-tram';
            break;
          case 4:
            icon = 'fa-bus';
            break;
          case 5:
            icon = 'fa-car';
            break;
          case 6:
            icon = 'fa-ship';
            break;
          default:
            icon = 'fa-location-arrow';
            break;
        }

        transportsRaw.push({
          title: element.title,
          text: element.text,
          date: element.date ? this.dateFormatter.formatDate(new Date(new Date(element.date).getTime() + 24 * 3600000)) : undefined,
          dateRaw: element.date,
          icon,
        });
      });
      this.transports = transportsRaw.sort( (a, b) => a.dateRaw - b.dateRaw);
      this.provider.setContentLoading(false);
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private provider: ProviderService, private api: ApiService, private dateFormatter: DateFormatterService, private toaster: ToasterService) {
    this.route.params.subscribe( params => {
      this.projID = params.id;
      //this.provider.updateRootId(this.projID);
    });

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
  }
  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled=state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData();
  }

}
