<p-card 
    class="weather card" 
    pRipple
>
    <img [src]="weatherData.current.weather[0].id | weatherBG" id="weather-bg" *ngIf="weatherData"/>
    <ng-template pTemplate="header">
        <div class="header-wrapper">
            <h3 *ngIf="weatherData">{{weatherData.current.temp | weatherNum}}</h3><p *ngIf="weather">{{ weather.location }}</p>
            <i class="fa-solid" [class]="weatherData.current.weather[0].id | weatherIcon" *ngIf="weatherData"></i>
        </div>
    </ng-template>
    <div class="precast-wrapper" *ngIf="weatherData">
        <div *ngFor="let day of weatherData.daily | slice:0:5; let i=index" class="prec-el">
            <span class="prec-day">
                {{day.sunrise | weatherDate:weatherData.timezone_offset}}
            </span>
            <div class="prec-weather">
                <i class="fa-solid" [class]="day.weather[0].id | weatherIcon"></i>
                <span class="prec-temp">{{day.temp.day | weatherNum}}</span>
            </div>
        </div>
    </div>
</p-card>