<div class="container">
    <NavComponent title="Karte" isScrolled="false"></NavComponent>
    <div class="map-wrapper">
        <div *ngIf="apiLoaded | async" class="gmap-wrapper">
            <google-map #gmap [options]="mapsOptions"></google-map>
        </div>
        <div class="marker-picker" *ngIf="locations$ | async">
            <p-panel toggler="header" [toggleable]="true" expandIcon="pi pi-angle-double-up" collapseIcon="pi pi-angle-double-down" [collapsed]="collapsed">
                <ng-template pTemplate="header">
                    <div class="picker-header">
                        <span class="picker-loc">{{ activeLocation?.name }}</span>
                        <span class="index-disp">{{ activeLocation?.idx }}/{{ (locations$ | async)?.length }}</span>
                    </div>
                    
                </ng-template>
                <p-dataView [value]="locations$ | async" [paginator]="true" [rows]="3" [pageLinks]="3" [alwaysShowPaginator]="false" [showFirstLastIcon]="false">
                    <ng-template let-location pTemplate="listItem">
                        <div class="marker-entry" (click)="jumpMarker(location.id)">
                            <span><strong>{{ location.idx +1 }}</strong> | {{ location.name }}</span>
                            <a class="marker-button" pRipple aria-label="Pin zentrieren" label="Show">
                                <i class="fa-solid fa-location-pin marker-icon" aria-hidden="true"></i>
                            </a>
                        </div>
                    </ng-template>
                </p-dataView>
            </p-panel>
        </div>
    </div>
</div>