import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { DateFormatterService } from '../date-formatter.service';
import { ToasterService } from '../toaster.service';

@Component({
  selector: 'ProgramPreview',
  templateUrl: './program-preview.component.html',
  styleUrls: ['./program-preview.component.scss']
})
export class ProgramPreviewComponent implements OnInit {

  meta: any = {};
  day: any[] = [];

  constructor(private api: ApiService, private dateFormatter: DateFormatterService, private toaster: ToasterService) { }
  
  getData() {
    this.api.getProgram().subscribe( {
      next: (data: any) => {
        //console.log(data);
        const curr = new Date();

        const sortedDays = data.sort( (a, b) =>  a.date.localeCompare(b.date));
        sortedDays.map( day => {
          day.date = new Date(new Date(day.date).getTime());
        })
        let matchDay = sortedDays.filter( day => {
          return day.date.getMonth() == curr.getMonth() && day.date.getDate() == curr.getDate();
        })
        let cDay = matchDay.length == 0 ? sortedDays[0] : matchDay[0];
        this.meta.day = cDay.date;
        const isActiveDay = cDay.date.getMonth() == curr.getMonth() && cDay.date.getDate() == curr.getDate();
        //if date matches today, trimm to current time
        if(isActiveDay) {
          if(curr.getHours() >= 9) {
            cDay.dawn = [];
          } if(curr.getHours() >= 12) {
            cDay.morning = [];
          } if(curr.getHours() >= 15) {
            cDay.noon = [];
          } if(curr.getHours() >= 18) {
            cDay.afternoon = [];
          }
        }
        this.day = [...cDay.dawn, ...cDay.morning, ...cDay.noon, ...cDay.afternoon, ...cDay.evening]
        if(this.day.length < 2 && isActiveDay) {
          const recov = matchDay.length == 0 ? sortedDays[0] : matchDay[0]
          this.day = [...recov.dawn, ...recov.morning, ...recov.noon, ...recov.afternoon, ...recov.evening]
        }
      }, error: (err:HttpErrorResponse) => {
        this.toaster.handleApiError();
      }
    });
  }

  ngOnInit(): void {
    this.getData();
  }

}
