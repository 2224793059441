import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from '../api.service';
import { ProviderService } from '../provider.service';
import { ToasterService } from '../toaster.service';

@Component({
  selector: 'WeatherWidget',
  templateUrl: './weather-widget.component.html',
  styleUrls: ['./weather-widget.component.scss']
})
export class WeatherWidgetComponent implements OnInit {

  @Input() weather: any;
  weatherData: any;
  tz: string;

  constructor(private api: ApiService, private provider: ProviderService, private toaster: ToasterService) { 
    
  }

  getData() {
    this.api.getWeather(this.weather.lat, this.weather.lon).subscribe({
      next: (data: any) => {
        //console.log(data);
        this.weatherData = data;
      },
      error: (err: HttpErrorResponse) => {
        this.toaster.handleApiError();
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.weather && this.weather.lat && this.weather.lon) {
        this.getData();
    }
  }


  ngOnInit(): void {
    //this.getData();
  }

}
