<div class="container">
    <div class="header">
        <div class="title-box">
            <h3>Impressum</h3>
        </div>
    </div>
    <p class="title"><strong>Eurotravel Solutions GmbH</strong></p>
    <p>Vogelweiderstraße 63<br>
    A-5020 Salzburg</p>
    <p>Tel: <a href="tel:+43662243603">+43 662 243603</a><br>
    Fax: +43 662 243603 -15<br>
    Mail: <a href="mailto:info@eurotravel-solutions.com">info@eurotravel-solutions.com</a></p>
    <p>CEO: Mateja Bauer<br>
    UID: ATU66080037<br>
    Firmenbuchnummer: FN349253h</p>
    
    <p class="small">Für den Inhalt verantwortlich: Eurotravel Solutions GmbH<br>
    Änderungen und Irrtümer vorbehalten.<br>
    Bilder: Eurotravel Solutions, istockphoto.com, fotolia.com</p>
</div>