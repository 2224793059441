import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherBG'
})
export class WeatherBGPipe implements PipeTransform {


  /**
   * @param {number} code - weather group id from openweathermap
   * @returns {string} - material icon name for main weather group
   */
  weatherMap = {
    200: 'lightning',  // thunderstorm with light rain
    201: 'lightning',  // thunderstorm with rain
    202: 'lightning',  // thunderstorm with heavy rain
    210: 'lightning',  // light thunderstorm
    211: 'lightning',  // thunderstorm
    212: 'lightning',  // heavy thunderstorm
    221: 'lightning',  // ragged thunderstorm
    230: 'lightning',  // thunderstorm with light drizzle
    231: 'lightning',  // thunderstorm with drizzle
    232: 'lightning',  // thunderstorm with heavy drizzle
    300: 'clouds', // light intensity drizzle
    301: 'rain', // drizzle
    302: 'rain', // heavy intensity drizzle
    310: 'rain', // light intensity drizzle rain
    311: 'rain', // drizzle rain
    312: 'rain', // heavy intensity drizzle rain
    313: 'rain', // shower rain and drizzle
    314: 'rain', // heavy shower rain and drizzle
    321: 'rain', // shower drizzle
    500: 'clouds', // light rain
    501: 'rain', // moderate rain
    502: 'rain', // heavy intensity rain
    503: 'rain', // very heavy rain
    504: 'rain', // extreme rain
    511: 'rain', // freezing rain
    520: 'rain', // light intensity shower rain
    521: 'rain', // shower rain
    522: 'rain', // heavy intensity shower rain
    531: 'rain', // ragged shower rain
    600: 'snow',  // light snow
    601: 'snow',  // snow
    602: 'snow',  // heavy snow
    611: 'snow',  // sleet
    612: 'snow',  // shower sleet
    615: 'snow',  // light rain and snow
    616: 'snow',  // rain and snow
    620: 'rain',  // light shower snow
    621: 'rain',  // shower snow
    622: 'rain',  // heavy shower snow
    700: 'fog', // mist
    711: 'fog', // smoke
    721: 'fog', // haze
    731: 'fog', // sand, dust whirls
    741: 'fog', // fog
    751: 'fog', // sand
    761: 'fog', // dust
    762: 'fog', // volcanic ash
    771: 'fog', // squalls
    781: 'clouds', // tornado
    800: 'clear', // clear sky
    801: 'sun', // few clouds
    802: 'clouds',  // scattered clouds
    803: 'clouds', // broken clouds
    804: 'clouds', // overcast clouds
  }

  transform(code: number, ...args: unknown[]): string {
    return `/assets/imgs/${this.weatherMap[code]}.jpg`;
  }

}
