import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherNum'
})
export class WeatherNumPipe implements PipeTransform {

  transform(value: number, args?: any): string | null {
    let res = Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)
    return Number.parseInt(res)+'°';
  }

}
