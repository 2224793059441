import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'NavComponent',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnChanges{

  @Input() title:string;
  @Input() isScrolled:boolean;
  
  @ViewChild('nav') nav:ElementRef;

  elevated: boolean = false;

  constructor(private location:Location) {
  }

  /* registerScrollEvent() {
    console.log(this.nav.nativeElement)
    setTimeout(() => this.nav.nativeElement.onscroll = () => {
      if(!this.elevated && document.querySelector(this.scrollSelector).scrollTop > 0) {
        this.nav.nativeElement.classList.add('elevated');
        this.elevated = true;
      } else if(this.elevated && document.querySelector(this.scrollSelector).scrollTop === 0) {
        this.nav.nativeElement.classList.remove('elevated');
        this.elevated = false;
      }
    });
  } */

  ngOnChanges(changes: SimpleChanges): void {
    if(this.nav && changes && changes.isScrolled) {
      if(!this.elevated && this.isScrolled) {
        this.nav.nativeElement.classList.add('elevated');
        this.elevated = true;
      } else if(this.elevated && !this.isScrolled) {
        this.nav.nativeElement.classList.remove('elevated');
        this.elevated = false;
      }
    }
  }

  goBack() {
    this.location.back();
  }

}
