import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  weekdays = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'];

  private calcLeadingZero(num: number) {
    return num < 10 ? `0${num}` : num.toString();
  }

  private calcCurrentDayDiff(now: Date, date: Date): number {
    const diff = Math.ceil((now.getTime() - date.getTime()) / 86400000);
    return diff;
  }

  formatDate(date: Date) {
    return `${this.calcLeadingZero(date.getDate())}.${this.calcLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}`;
  }

  formatTime(date: Date) {
    const now = new Date();
    const diff =this.calcCurrentDayDiff(now, date);
    if ( diff > 1) {
      if ( diff < 7) {
        if( now.getDate()-date.getDate()==1 ) {
          return `Gestern, ${date.getHours()}:${date.getMinutes()}`;
        }
        return `${this.weekdays[date.getDay()]} ${date.getHours()}:${date.getMinutes()}`;
      } else {
        return `${date.getDate()}.${date.getMonth() + 1}.`;
      }
    } else {
      if ( now.getDay()!=date.getDay() ) {
        return `Gestern, ${date.getHours()}:${date.getMinutes()}`;
      } else {
        return `${date.getHours()}:${date.getMinutes()}`;
      }
    }
  }

  constructor() { }
}
