import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';
import { ApiService } from '../api.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HasScrollable } from '../has-scrollable';

@Component({
  selector: 'app-accomodation',
  templateUrl: './accomodation.component.html',
  styleUrls: ['./accomodation.component.scss']
})
export class AccomodationComponent implements OnInit, HasScrollable {

  private projID: number;

  description = {
    text: '',
    available: false
  };

  accommodations = [];

  getData = function() {
    this.api.getAccommodationsDescription().subscribe((data: any) => {
      if(data.description!="" && data.description!="<br>" && data.description!=" ") {
        this.description.text=data.description;
        this.description.available = true;
        this.provider.setContentLoading(false);
      }
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
    this.api.getAccommodationList().subscribe((data: any) => {
      data.forEach((element) => {
        this.accommodations.push({
          name: element.name,
          prev: element.description,
          address: element.address,
          image: this.api.getApiRoot() + element.imageLink,
          id: element.acc_id,
          loaded: true
        });
      });
      this.provider.setContentLoading(false);
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private route: ActivatedRoute, private provider: ProviderService, private api: ApiService, private toaster: ToasterService) {
    this.route.params.subscribe( params => {
      this.projID = params.id;
      //this.provider.updateRootId(this.projID);
    });

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });
  }
  isScrolled: boolean;
  setScrollState(state: boolean) {
    this.isScrolled=state;
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.getData();
  }

}
