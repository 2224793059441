import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ProviderService } from '../provider.service';
import { ToasterService } from '../toaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  token = undefined;
  loadingShortCode=true;
  shortCode = '';
  card=false;

  // if query param, check login and redirect to home
  // if no query param, check for local token check auth and redirect to home
  // if no token and no param, display info message
  private checkToken() {

    if(navigator.serviceWorker.controller && navigator.serviceWorker.controller.state !== 'activated') {
      setTimeout(() => {
        this.checkToken();
      }, 500);
      return;
    }

    //purge serviceworker cache
    if(navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'purge'
      });
    }

    this.api.checkToken(this.token).then((data: any) => {

      this.provider.setToken(data.token);
      this.api.refreshToken();

      if(!this.provider.isIos()) this.router.navigate(['/']);
      else {
        this.card=true;
        this.api.makeShortcode().subscribe((data: any) => {
          this.shortCode=data.shortcode;
          this.loadingShortCode=false;
        }, (err: HttpErrorResponse) => {
          this.toaster.handleApiError();
          this.shortCode='Fehler beim Erstellen'
        });
      }

      //this.router.navigate(['/']);

      /*this.provider.setToken(data.token).then( () => {
        if(this.provider.isIos()){
          this.provider.mirrorToken().then( () => {
            this.api.refreshToken();
            this.router.navigate(['/']);
          })
        } else {
          this.api.refreshToken();
          this.router.navigate(['/']);
        }
        
      })*/

    }).catch( (err: HttpErrorResponse) => {
      this.provider.setErrorLoading(true);
    }).finally( () => {
      this.provider.setContentLoading(false);
    });
  
  }

  //Copy the shortCode to clipboard and show success message
  shortcodeCopy() {
    navigator.clipboard.writeText(this.shortCode);
    this.toaster.popToast('Kopiert' , 'In die Zwischenablage kopiert!');
  }

  onNoCLick() {
    this.card=false;
  }

  constructor(private route: ActivatedRoute, private toaster: ToasterService, private router: Router ,private api: ApiService, private provider: ProviderService){
    this.provider.nav(false);
    this.route.queryParamMap.subscribe( params => {
      this.token = params.get('token');
    });
  }

  ngOnInit() {
    this.provider.pageLoading();
    this.checkToken();
  }

  ngOnDestroy() {
    this.provider.nav(true);
  }

}
