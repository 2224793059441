import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[navScrollable]'
})
export class NavScrollableDirective {

  constructor() { }

  @HostListener('scroll', ['$event.target']) onScroll(el: any) {
    this.scrolled.emit(el.scrollTop > 0);
  }

  @Output() scrolled:EventEmitter<any> = new EventEmitter();

}
