import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeStamp'
})
export class TimeStampPipe extends DatePipe implements PipeTransform {

  private calcCurrentDayDiff(now: Date, date: Date): number {
    const diff = Math.ceil((now.getTime() - date.getTime()) / 86400000);
    return diff;
  }

  transform(date: Date, args?: any): any {
    let tzo = super.transform(args, 'HHmm') ?? undefined;

    const now = new Date();
    const diff =this.calcCurrentDayDiff(now, date);
    if ( diff > 1) {
      if ( diff < 7) {
        if( now.getDate()-date.getDate()==1 ) {
          return `Gestern, ${super.transform(date, "H:mm", tzo ? tzo : undefined)}`;
        } else {
        return super.transform(date, "EEEEEE H:mm", tzo ? tzo : undefined);
        }
      } else {
        return super.transform(date, "d. LLL", tzo ? tzo : undefined);
      }
    } else {
      if ( now.getDay()!=date.getDay() ) {
        return `Gestern, ${super.transform(date, "H:mm", tzo ? tzo : undefined)}`;
      } else {
        return super.transform(date, "H:mm", tzo ? tzo : undefined);
      }
    }
  }

}
