import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { ToasterService } from '../toaster.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, Subject, take } from 'rxjs';
import { GoogleMap } from '@angular/google-maps';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

    @ViewChild(GoogleMap) gmapElement: GoogleMap;

  private gMapStyleArray = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6195a0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#e6f3d6"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f4d2c5"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eaeaea"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#787878"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f0f0f0"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#999999"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#00c5ff"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#eaf6f8"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eaf6f8"
            }
        ]
    }
];

  private projID: number;
  apiLoaded: Observable<boolean>;
  activeLocation: {name: string, idx: number} = {name: 'Wird geladen...', idx: 0};

  private mLocations = new BehaviorSubject<any>([]);
  locations$ = this.mLocations.asObservable();
  private markers: google.maps.Marker[];
  
  mapsOptions: google.maps.MapOptions = {
    //mapId: 'c93013a8e8164015',
    styles: this.gMapStyleArray,
    zoom: 13,
    minZoom: 6,
    center: {lat: 47.8, lng: 13.05},
    disableDefaultUI: true,
    zoomControl: true,
    scaleControl: true,
    rotateControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 2,
      position: 1
    }
  };
  collapsed: boolean = true;

  getData = function(id: number) {

    this.api.getMaps(this.projID).subscribe((data: any) => {
        let locs = [];
        var placesService = new google.maps.places.PlacesService(this.gmapElement.googleMap);
        let locP = data.map(async (element: any, i: number) => {

            return await new Promise((resolve, reject) => {
                 setTimeout(() => placesService.findPlaceFromQuery({
                    query: decodeURIComponent(element.place.replace(/\+/g, '%20')), 
                    locationBias: new google.maps.LatLng({lat: parseFloat(element.lat), lng: parseFloat(element.lon)}),
                    fields: ['place_id', 'geometry']
                }, (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        resolve({
                            name: element.name,
                            placeId: results[0].place_id,
                            id: element.id,
                            place: decodeURIComponent(element.place.replace(/\+/g, '%20')),
                            location: results[0].geometry.location,
                            idx: i
                        });
                    } else {
                        reject(status);
                    }
                }), Math.floor(i / 3) * 500 );
            });
        })
        Promise.all(locP).then((val) => {
            this.mLocations.next(val);
        }).catch((err) => {
            console.log(err)
        });
    }, ( err: HttpErrorResponse ) => {
      this.toaster.handleApiError();
      throw err;
    });
  };

  constructor(private httpClient: HttpClient, private api: ApiService, private toaster: ToasterService) {
  }

  ngAfterViewInit() {
    this.apiLoaded = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.gmap_api_key}&libraries=places`, 'callback')
        .pipe(
          map(() => {this.initMap(this); this.getData(this.projID); return true;}),
          catchError((err, caught) => {

            console.log(err) 
            return of(false)
          }),//of(false)),
        );
  }

  ngOnInit() {
    
  }

  jumpMarker(id: number) {
    this.locations$.pipe(take(1)).subscribe((curr: any[]) => {
        curr.map((elem) => {
            if (elem.id === id) {
                this.gmapElement.googleMap.setCenter(elem.location);
                this.activeLocation.name = elem.name;
                this.activeLocation.idx = elem.idx + 1;
            }
        });
    }).unsubscribe();
  }

  placeMarker = async (location: { location: any; name: any; id: number; place: string, idx: number}, ctx) => {
    let marker = new google.maps.Marker({
        position: location.location,
        map: ctx.gmapElement?.googleMap,
        title: location.name,
        label: {
            text: (location.idx + 1).toString(),
            color: 'white',
            fontSize: '1.1rem',
            fontWeight: 'bold',
        }
    });
    this.markers.push(marker);

  }

  clearMarkers = () => {
    this.markers?.map(marker => marker.setMap(null));
    this.markers = [];
  }

  initMap = (ctx) => {
    if(!ctx.gmapElement) return setTimeout(() => this.initMap(ctx), 200);

    this.locations$.subscribe( (data) => {
        if(!data || data.length == 0) return;
        this.clearMarkers();

        data.map(loc => this.placeMarker(loc, ctx));
        ctx.gmapElement.googleMap.setCenter(data[0].location);
        ctx.activeLocation.name = data[0].name;
        ctx.activeLocation.idx = data[0].idx + 1;
    });
  }
}
