import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {

  constructor(private route:ActivatedRoute, private provider:ProviderService) {
    this.route.params.subscribe( params => {
      //this.provider.updateRootId(params["id"]);
    })
    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data["depth"]);
    })
  }

  ngOnInit() {
  }

}
