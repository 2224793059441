import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  static readonly DEFAULT_ERROR_TITLE: string = 'Tut uns leid!';
  static readonly API_ERROR_MESSAGE: string = 'Es ist ein Fehler aufgetreten. Versuche es bitte später erneut.';
  static readonly CONNECTION_ERROR_MESSAGE: string = 'Es konnte keine Verbindung aufgebaut werden. Versuche es bitte später erneut.';
  static readonly DEFAULT_ERROR_MESSAGE: string = 'Es ist ein Fehler aufgetreten. Versuche es bitte später erneut.';
  static readonly ROUTE_ERROR_MESSAGE: string = 'Ungültige Reise!';
  static readonly NO_DATA_MESSAGE: string = 'Es sind keine Einträge vorhanden';

  constructor(private snackBar: MatSnackBar) { }

  popToast(title: string, text: string, action?: any) {
    if( action ) {
      const snack = this.snackBar.open(text, 'OK');
      snack.onAction().subscribe(action);
    } else {
      const snack = this.snackBar.open(text, undefined, {duration: 10000});
    }
  }

  handleNetworkError() {
    this.popToast(ToasterService.DEFAULT_ERROR_TITLE, ToasterService.CONNECTION_ERROR_MESSAGE, () => {
    });
  }

  handleApiError() {
    this.popToast(ToasterService.DEFAULT_ERROR_TITLE, ToasterService.API_ERROR_MESSAGE);
  }
}
