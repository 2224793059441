<div class="static-wrapper" *ngIf="images.length > 0">
    <div class="bg-static" [ngStyle]="{'background-image': 'url(' + images[0] + ')'}"></div>
</div>
<div class="wrapper" [class.no-img]="images.length < 1" #nav>
    <a class="back-button header-button" pRipple aria-label="Zurück" (click)="goBack()">
        <i class="fa-solid fa-arrow-left nav-icon" aria-hidden="true"></i>
    </a>
    <div class="title-wrapper"><h3 class="title">{{ title }}</h3></div>
    <a *ngIf="images.length > 0" class="gallery-button header-button" pRipple aria-label="Gallerie" label="Show" (click)="openGallery = true">
        <i class="fa-solid fa-image gallery-icon" aria-hidden="true"></i>
    </a>
</div>
<p-galleria *ngIf="images.length > 0" [(value)]="images" [(visible)]="openGallery" [responsiveOptions]="galleryResponsiveOpt" [containerStyle]="{'max-width': '90%'}" [numVisible]="3"
    [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [baseZIndex]="100000">
    <ng-template pTemplate="item" let-item>
        <img [src]="item" style="width: 100%; display: block;"/>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
            <img [src]="item" style="display: block; max-width: 95%;"/>
        </div>
    </ng-template>
</p-galleria>
