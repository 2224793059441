import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ProviderService } from './provider.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  //private token=undefined;

  private httpOptions = this.makeOptions();

  private makeOptions() {
    let token = this.provider.getToken();
    return {
      headers: new HttpHeaders({
        'Authorization': (token!=='' && token!==undefined) ? 'Bearer ' + token : undefined,
      }),
      withCredentials: true,
    }
  }

  constructor(private http: HttpClient, private provider: ProviderService) {}

  refreshToken() {
    this.httpOptions=this.makeOptions();
  }

  /*checkToken = (token: string): Observable<any> => {
    return this.http.get(`${environment.api}authenticate`, {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    }),
    withCredentials: true,});
  }

  getTkn = async (): Promise<any> => {
    return this.http.get(`${environment.api}/tkn-cache-v1`)
  }*/

  checkToken = (token: string): Promise<any> => {
    return fetch(`${environment.api}authenticate`, 
    {
      method: "GET", 
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      //withCredentials: true,
    }).then(res => res.json());
  }

  getTkn = (): Promise<any> => {
    return fetch(`${environment.api}tkn-cache-v1`).then(res => res.text());
  }

  makeShortcode = (): Observable<any> => {
    return this.http.get(`${environment.api}shortcode`, this.httpOptions);
    /* const res = await fetch(`${environment.api}program`);
    return await res.json(); */
  }

  checkShortcode = (shortcode: string): Observable<any> => {
    return this.http.post(`${environment.api}shortcode`,  {shortcode: shortcode});
  }
  // returns a link to the client logo via project id
  getCompanyImageLink = (id: number) => {
    return `${environment.api}client/logo?id=${id}`;
  }

  getCacheManifest = (): Promise<any> => { 
    return fetch(`${environment.api}precache`, 
    {
      method: "GET", 
      headers: {
        'Authorization': this.httpOptions.headers.get('Authorization'),
      },
      //withCredentials: true,
    }).then(res => res.json());
    //return this.http.get(`${environment.api}precache`, this.httpOptions)
    //.pipe(retry(1))
  }

  registerInstall = (): Observable<any> => {
    return this.http.post(`${environment.api}reginstall`, '1', this.httpOptions).pipe(retry(1));
  }

  registerUnique = (): Observable<any> => {
    return this.http.post(`${environment.api}regunique`, '1', this.httpOptions).pipe(retry(1));
  }

  // returns a promise of a fetch for on object with project information
  getProjectInfo = (): Observable<any> => {
    /*const res = await fetch(`${environment.api}project`);
    return await res.json();*/
    
    return this.http.get(`${environment.api}project`, this.httpOptions)
    .pipe(retry(1))
    //return { Project_Title: "Test", Project_Date_Start: "", Project_Date_End: ""};
    //return await res.json();
  }

  getWeather = (lat: number, lon: number): Observable<any> => {
    /*const res = await fetch(`${environment.api}project`);
    return await res.json();*/
    
    return this.http.get(`${environment.api}weather`, { params: new HttpParams().appendAll({lat, lon}), ...this.httpOptions})
    .pipe(retry(1))
    //return { Project_Title: "Test", Project_Date_Start: "", Project_Date_End: ""};
    //return await res.json();
  }

  getProgram = (): Observable<any> => {
    return this.http.get(`${environment.api}program`, this.httpOptions);
    /* const res = await fetch(`${environment.api}program`);
    return await res.json(); */
  }

  getProgrammDetail = (id: number): Observable<any> => {
    return this.http.get(`${environment.api}program/detail?id=${id}`, this.httpOptions);
    /* const res = await fetch(`${environment.api}program/detail?id=${id}`);
    return await res.json(); */
  }

  getDestinationList = (): Observable<any> => {
    return this.http.get(`${environment.api}destinations`, this.httpOptions);
    /* const res = await fetch(`${environment.api}destinations`);
    return await res.json(); */
  }

  getDestination = (id: number): Observable<any> => {
    return this.http.get(`${environment.api}destination?id=${id}`, this.httpOptions);
    /* const res = await fetch(`${environment.api}destination?id=${id}`);
    return await res.json(); */
  }

  getInfos = (): Observable<any> => {
    return this.http.get(`${environment.api}infos`, this.httpOptions);
    /* const res = await fetch(`${environment.api}infos`);
    return await res.json(); */
  }

  getTransports = (): Observable<any> => {
    return this.http.get(`${environment.api}transports`, this.httpOptions);
    /* const res = await fetch(`${environment.api}transports`);
    return await res.json(); */
  }

  getAccommodationList = (): Observable<any> => {
    return this.http.get(`${environment.api}accommodations`, this.httpOptions);
    /* const res = await fetch(`${environment.api}accommodations`);
    return await res.json(); */
  }

  getAccommodationsDescription = (): Observable<any> => {
    return this.http.get(`${environment.api}accommodations/description`, this.httpOptions);
    /* const res = await fetch(`${environment.api}accommodations/description`);
    return await res.json(); */
  }

  getAccommodation = (id: number): Observable<any> => {
    return this.http.get(`${environment.api}accommodation?id=${id}`, this.httpOptions);
    /* const res = await fetch(`${environment.api}accommodation?id=${id}`);
    return await res.json(); */
  }

  getMaps = (): Observable<any> => {
    return this.http.get(`${environment.api}maps`, this.httpOptions);
    /* const res = await fetch(`${environment.api}maps`);
    return await res.json(); */
  }

  getMap = (id: number): Observable<any> => {
    return this.http.get(`${environment.api}map?id=${id}`, this.httpOptions);
    /* const res = await fetch(`${environment.api}map?id=${id}`);
    return await res.json(); */
  }

  getNotifications = (): Observable<any> => {
    return this.http.get(`${environment.api}notifications`, this.httpOptions);
    /* const res = await fetch(`${environment.api}notifications`);
    return await res.json(); */
  }

  getNotificationCount = (): Observable<any> => {
    return this.http.get(`${environment.api}notifications/count`, this.httpOptions)
    .pipe(retry(1))
    /* const res = await fetch(`${environment.api}notifications/count`);
    return await res.json(); */
  }

  getApiRoot = () => {
    return environment.api;
  }
}
