<div class="container" navScrollable (scrolled)="setScrollState($event)">
    <NavComponent title="Transport" [isScrolled]="isScrolled"></NavComponent>
    <p-card 
        class="transport card" 
        pRipple
        *ngFor="let transport of transports"
    >
        <ng-template pTemplate="header">
            <div class="header-wrapper">
                <h3>{{transport.title}}</h3>
                <p *ngIf="transport.date">{{transport.date}}</p>
                <!--p class="icon-line"><mat-icon class="transport-icon"> {{transport.icon}} </mat-icon><i class="transport-icon fa-solid" [ngClass]="transport.icon"></i></p-->
            </div>
        </ng-template>
        <p-divider align="center">
            <div class="inline-flex align-items-center">
                <i class="transport-icon fa-solid" [ngClass]="transport.icon"></i>
            </div>
        </p-divider>
        <p [innerHTML]=transport.text></p>
    </p-card>
</div>