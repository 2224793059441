<div class="container" navScrollable (scrolled)="setScrollState($event)">
    <GalleryHeader [images]="slides" [title]="detail.name" [isScrolled]="isScrolled"></GalleryHeader>

    <div class="content-wrapper">
        <p [innerHTML]=detail.description></p>
    </div>
        <!--mat-card class="card">
            <mat-carousel class="carousel"
                timings="270ms cubic-bezier(0.4, 0.0, 0.2, 1)"
                [autoplay]="true"
                interval="7500"
                maxWidth="auto"
                proportion="60"
                slides="slides.length"
                [loop]="true"
                [hideArrows]="true"
                [hideIndicators]="false"
                [useKeyboard]="false"
                [useMouseWheel]="false"
                orientation="ltr"
                >
                <mat-carousel-slide
                    #matCarouselSlide
                    *ngFor="let slide of slides; let i = index"
                    [image]="slide"
                    overlayColor="#00000040"
                    [hideOverlay]="true"
                ></mat-carousel-slide>
            </mat-carousel>
            <mat-card-content>
                <p [innerHTML]=detail.description></p>
            </mat-card-content>
        </mat-card-->              
    </div>